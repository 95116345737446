import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ReactLoading from 'react-loading'
import {
  Card,
  CardHeader,
  Row,
  Button,
  Col,
  Table
} from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { loadModelCategories, openEditModelCategoryModal, openAddModelCategoryModal, openDeleteModelCategoryModal } from 'redux/actions/modelCategories'
import { formatCurrency } from 'utils/numberFormat'
import moment from 'moment'

const DependenciesList = ({
  modelCategories,
  lands,
  openAddModelCategoryModal,
  openDeleteModelCategoryModal,
  openEditModelCategoryModal,
  loading,
  match,
  loadModelCategories,
  loadingModel,
  hasEditPermission,
  hasDeletePermission,
}) => {
  const [hasFetchedModelCategories, setHasFetchedModelCategories] = useState(false)

  useEffect(() => {
    if (!hasFetchedModelCategories) {
      loadModelCategories(match.params.id)
      setHasFetchedModelCategories(true)
    }
  // eslint-disable-next-line
  }, [match.params.id, loadingModel, loadModelCategories])

  return (
    <Row>
      <Col className="order-xl-1 mt-3" xl="12">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs={12}>
                <h6 className="heading-small text-muted">
                  Categorías
                  <Button
                    color="primary"
                    size="sm"
                    className="float-right mt--1"
                    onClick={() => openAddModelCategoryModal({ id: parseInt(match.params.id) })}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faFileContract} />
                    Añadir categoría a modelo
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </h6>
              </Col>
            </Row>
          </CardHeader>
          {loading && <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block m-4" />}
          {!loading && modelCategories.length === 0 && <div className="text-mute text-sm p-3">No hay categorías relacionadas a este modelo.</div>}
          {!loading && modelCategories.length > 0 && (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="text-center">Nombre</th>
                  <th scope="col" className="text-center">Código</th>
                  <th scope="col" className="text-center">Límite</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {modelCategories.map(modelCategory => (
                  <tr key={modelCategory.id}>
                    <th scope="row" className="text-center">
                      <Button tag={Link} to={`/admin/categories/${modelCategory.category.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                        {modelCategory.category.name}
                      </Button>
                    </th>
                    <th className="border-left text-center mb-0 text-sm">
                      {modelCategory.category.description}
                    </th>
                    <td className="border-left text-center mb-0 text-sm">
                      {formatCurrency({amount: modelCategory.limit})}
                    </td>
                    <td className="border-left text-center">
                      {hasEditPermission && <Button
                        color="primary"
                        size="sm"
                        className="mr-2"
                        onClick={() => openEditModelCategoryModal(modelCategory)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>}
                      {hasDeletePermission && <Button
                        color="secondary"
                        size="sm"
                        onClick={() => openDeleteModelCategoryModal(modelCategory)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs={12}>
                <h6 className="heading-small text-muted">
                  Lotes
                </h6>
              </Col>
            </Row>
          </CardHeader>
          {loadingModel && <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block m-4" />}
          {lands.length === 0 && !loadingModel && <div className="text-mute text-sm p-3">Sin lotes</div>}
          {!loadingModel && lands.length > 0 && (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th/>
                  <th scope="col" className="text-center">Número</th>
                  <th scope="col" className="text-center">Calle</th>
                  <th scope="col" className="text-center">Licencia de construcción</th>
                  <th scope="col" className="text-center">Predial pagado</th>
                  <th scope="col" className="text-center">Presupuesto</th>
                </tr>
              </thead>
              <tbody>
                {lands.map(land => (
                  <tr key={land.id}>
                    <th scope="row" className="border-left text-center">
                      <Button
                        tag={Link}
                        to={`/admin/lands/${land.id}`}
                        className="btn btn-sm btn-outline-primary mr-0"
                      >
                        Ver
                      </Button>
                    </th>
                    <td className="border-left mb-0 text-sm">
                      {land.number}
                    </td>
                    <td className="border-left mb-0 text-sm">
                      {land.street}
                    </td>
                    <td className="border-left text-center">
                      {(land.has_license && <label>Con licencia</label>) ||
                        <label>Sin licencia</label>
                      }
                    </td>
                    <td className="border-left text-center">
                      {(land.is_predial_paid && <label>{ moment(land.is_predial_paid).format('DD-MM-YYYY') }</label>) ||
                        <label>No pagado</label>
                      }
                    </td>
                    <td className="border-left text-center"> {formatCurrency({amount: land.paid_amount})} / { formatCurrency({amount: land.limit}) }</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </Col>
    </Row>
  )
}

DependenciesList.propTypes = {
  modelCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    limit: PropTypes.number,
    model: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
      project: PropTypes.number,
    }),
    category: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
    }),
  })),
  lands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    street: PropTypes.string,
  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingModel: PropTypes.bool.isRequired,
  loadModelCategories: PropTypes.func.isRequired,
  openAddModelCategoryModal: PropTypes.func.isRequired,
  openDeleteModelCategoryModal: PropTypes.func.isRequired,
  openEditModelCategoryModal: PropTypes.func.isRequired,
}

const mapStateToProps = ({ session: { user }, modelCategories: { modelCategories, loading }, models: { loadingModel, form: { lands } } }) => ({
  modelCategories,
  loading,
  loadingModel,
  lands,
  hasEditPermission: user && (user.isSuper || !!user.permissions.find(permission => permission.identifier === 'modelCategory.update')),
  hasDeletePermission: user && (user.isSuper || !!user.permissions.find(permission => permission.identifier === 'modelCategory.destroy')),
})

const mapDispatchToProps = dispatch => ({
  loadModelCategories: model => dispatch(loadModelCategories({ model }, {perPage: 1000})),
  openAddModelCategoryModal: model => dispatch(openAddModelCategoryModal(model)),
  openDeleteModelCategoryModal: model => dispatch(openDeleteModelCategoryModal(model)),
  openEditModelCategoryModal: model => dispatch(openEditModelCategoryModal(model)),
})

DependenciesList.defaultProps = {
  lands: [],
  user: {
    isSuper: false,
    permissions: [],
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DependenciesList))
