import React from 'react'
import {
  Table,
  Card,
  CardTitle,
  CardBody,
  Input,
  Button
} from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { formatCurrency } from 'utils/numberFormat'

const SelectNotes = props => {
  const { notes, register, totales: { total, subtotal, iva }, onSelectNote, readOnly, suppliers } = props

  const getsupplier = id => suppliers.find(supplier => supplier.id === parseInt(id))

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <h4>
            {readOnly ? 'Notas de la factura' : 'Seleccione las notas a agregar a la factura'}
          </h4>
        </CardTitle>
        <Table hover className="table-compact" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col" className="text-center">
                {/* <Input type="checkbox" className="form-checkbox" /> */}
              </th>
              <th scope="col" className="text-center">Fecha</th>
              <th scope="col" className="text-center">Número</th>
              <th scope="col" className="text-center">Proveedor</th>
              <th scope="col" className="text-right">Subtotal</th>
              <th scope="col" className="text-right">IVA</th>
              <th scope="col" className="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {notes.length === 0 && <tr><td colSpan={6}><h3 className="text-center">No hay notas para agregar</h3></td></tr>}
            {notes.map((note, index) => {
              const supplier = getsupplier(note.supplier)
              return (
                <tr key={note.id} className={`${note.selected ? 'row-selected' : ''}`}>
                  <td className="text-center mb-0 text-sm border-right">
                    {!readOnly && !note.invoice &&
                      <Input name={`notes[${index}]`} innerRef={register} value={note.id} type="checkbox" className="form-checkbox" onChange={e => onSelectNote(e.target.checked, note)} />
                    }
                  </td>
                  <td className="text-center mb-0 text-sm border-right">
                    {note.arrivalDate ? moment(note.arrivalDate).utc().format('DD/MMM/YYYY') : '---'}
                  </td>
                  <td className="text-center mb-0 text-sm border-right">
                    {note.number}
                  </td>
                  <td className="text-center mb-0 text-sm border-right flex-row justify-content-around align-items-center d-flex">
                    {supplier && <Button tag={Link} color="primary" to={`/admin/suppliers/${supplier.id}`} size="sm">{supplier.alias}</Button>}
                  </td>
                  <td className="text-right mb-0 text-sm border-right">
                    {formatCurrency({ amount: note.subtotal })}
                  </td>
                  <td className="text-right mb-0 text-sm border-right">
                    {formatCurrency({ amount: note.iva })}
                  </td>
                  <td className="text-right mb-0 text-sm border-right">
                    {formatCurrency({ amount: note.total })}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot className="thead-light">
            <tr>
              <th colSpan={4} className="text-right"><h5>Total</h5></th>
              <th className="text-right"><h5>{formatCurrency({ amount: subtotal })}</h5></th>
              <th className="text-right"><h5>{formatCurrency({ amount: iva })}</h5></th>
              <th className="text-right"><h5>{formatCurrency({ amount: total })}</h5></th>
            </tr>
          </tfoot>
        </Table>
      </CardBody>
    </Card >
  )
}

export default SelectNotes
