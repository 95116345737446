import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import ReactLoading from 'react-loading'
import get from 'lodash/get'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactDatetime from 'react-datetime'
import Select from 'react-select';

import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import moment from 'moment'

import AddNoteLand from 'components/Notes/AddNoteLand'
import DependenciesList from 'components/Notes/dependenciesList'
import { createNote } from 'redux/actions/notes'
import { formatCurrency } from 'utils/numberFormat'
import { getIva, getSubtotal } from 'utils/totales'

const tomorrow = moment().add(1, 'day')

const NoteForm = props => {
  const {
    loading,
    loadingNote,
    suppliers,
    supplier,
    onChange,
    projectId,
    landNotes,
    form,
    onSubmit,
    loadingProjects,
    projects,
    supplierId,
    project,
    readOnly,
    transactions,
    error,
  } = props

  const { register, errors, handleSubmit, reset } = useForm()

  const hasLandNotes = landNotes.length > 0 ? true : false
  const [selectedSupplier, setSupplier] = useState([]);
  const [supplierList, setSupplierList] = useState([]);

  const handleChange = (selectedOption) => {
    if(selectedOption)
    {
      setSupplier(selectedOption.id)
      onChange('supplierId', selectedOption.id)
    }
    else{
      setSupplier(null)
      onChange('supplierId',null)
    }
  }

  useEffect(() => {
    let total = form ? get(form, 'total', 0) : 0;
    total = total === null ? 0 : total
    const account = get(form, 'account')

    if (get(form, 'id') && get(transactions, 'transactions.length', 0) > 0) {
      reset({
        ...form,
        account: get(account, 'id'),
        subtotal: formatCurrency({ amount: getSubtotal(total) }),
        iva: formatCurrency({ amount: getIva(total) }),
        total: formatCurrency({ amount: total }),
      })
    }

    setSupplierList(suppliers.map(e => ({
      id:e.id,
      value:e.alias,
      label:e.alias,
    })))

  }, [form, reset, transactions,suppliers])

  return (
    <Form noValidate autoComplete="off">
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="input-block"
                >
                  Proyecto
                </Label>
                {loadingProjects && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}

                {!get(form, 'id') && !loadingProjects && projects && <Input
                  className="form-control-alternative"
                  disabled={hasLandNotes}
                  id="input-project"
                  autoComplete="off"
                  placeholder="Proyecto"
                  type="select"
                  onChange={e => onChange('projectId', e.target.value)}
                  name="project"
                  defaultValue={projectId}
                  innerRef={register({
                    required: true
                  })}
                >
                  {projects.map(project => <option value={project.id} key={project.id}>{project.name}</option>)}
                </Input>}
                {get(form, 'id') && <>
                  <br />
                  <Button tag={Link} to={`/admin/projects/${get(project, 'id')}`} className="text-sm btn btn-sm btn-outline-primary">
                    {get(project, 'name')}
                  </Button>
                  <Input
                    type="hidden"
                    innerRef={register}
                    name="project"
                  />
                </>}
                {errors.project && (
                  <FormText color="primary">
                    Seleccione un proyecto para la nota
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="form-control-label" htmlFor="arrivalDate">Fecha de llegada</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    className="form-control-alternative"
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                    closeOnSelect={true}
                    defaultValue={get(form, 'arrivalDate') ? moment(get(form, 'arrivalDate')).utc() : Date.now()}
                    isValidDate={current => current.isBefore(tomorrow)}
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: 'Fecha',
                      name: 'arrivalDate',
                      disabled: readOnly,
                      ref: register({
                        required: false,
                      }),
                    }}
                  />
                </InputGroup>
                {errors.date && errors.date.type === 'required' && (
                  <FormText color="primary">
                    Seleccione una fecha.
                  </FormText>
                )}
                {errors.date && errors.date.type === 'validate' && (
                  <FormText color="primary">
                    Ingrese una fecha válida o seleccione una en el calendario.
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col>
              <Label className="form-control-label" htmlFor="number">Número de Nota</Label>
              <Input
                type="text"
                className="form-control-alternative"
                name="number"
                disabled={readOnly}
                innerRef={register({
                  required: false
                })}
              />
            </Col>
            <Col>
              <FormGroup>
                <Label
                  className="form-control-label"
                  htmlFor="input-block"
                >
                  Proveedor
                </Label>
                {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                {!get(form, 'id') && !loading && suppliers &&
                <Select
                  className="form-control-alternative"
                  isDisabled={hasLandNotes}
                  isClearable={true}
                  isSearchable={true}
                  options={supplierList}
                  placeholder="Seleccione una opción"
                  onChange={handleChange}
                />
                }
                {!get(form, 'id') && !loading && suppliers && <Input
                  type="hidden"
                  id="input-supplier"
                  name="supplier"
                  value = {selectedSupplier}
                  innerRef={register({
                    required: true,
                    validate: value => value !== 'Seleccione una opción'
                  })}
                />}
                {get(form, 'id') && <>
                  <br />
                  <Button tag={Link} to={`/admin/suppliers/${get(supplier, 'id')}`} className="text-sm btn btn-sm btn-outline-primary">{get(supplier, 'alias', '')}</Button>
                  <Input
                    type="hidden"
                    innerRef={register}
                    name="supplier"
                  />
                </>}
                {errors.supplier && (
                  <FormText color="primary">
                    Seleccione un proveedor para la nota
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="payment-method">Forma de pago</Label>
                <Input
                  className="form-control-alternative"
                  defaultValue={form.payment_method ? form.payment_method: 3}
                  id="payment-method"
                  autoComplete="off"
                  type="select"
                  name="payment_method"
                  disabled={readOnly}
                  innerRef={register({
                    required: true
                  })}
                >
                  <option value="1">Efectivo</option>
                  <option value="2">Cheque</option>
                  <option value="3">Transferencia</option>
                  <option value="4">Tarjeta</option>
                </Input>
                {errors.payment_method && (
                  <FormText color="primary">
                    Seleccione un metodo de pago valido.
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="account">Cuenta <span className="text-danger">*</span></Label>
                {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                {!transactions.loading && transactions.transactions &&
                  <Input
                    className="form-control-alternative"
                    id="account"
                    autoComplete="off"
                    type="select"
                    name="account"
                    disabled={readOnly}
                    innerRef={register({
                      required: true,
                    })}
                  >
                    {[<option key={'emptyValue'} value="">Seleccione una opción</option>]
                      .concat(transactions.transactions
                        .map(transaction => <option value={transaction.id} key={transaction.id}>{transaction.name}</option>)
                      )
                    }
                  </Input>
                }
                {errors.account && (
                  <FormText color="primary">
                    Seleccione una cuenta
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          {form.total && form.total > 0 &&
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="subtotal">Subtotal</Label>
                  <Input
                    type="text"
                    className="form-control-alternative text-right"
                    name="subtotal"
                    readOnly
                    innerRef={register}
                  // value={formatCurrency({ amount: getSubtotal(total) })}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="iva">IVA</Label>
                  <Input
                    type="text"
                    className="form-control-alternative text-right"
                    name="iva"
                    readOnly
                    innerRef={register}
                  // value={formatCurrency({ amount: getIva(total) })}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="total">Total</Label>
                  <Input
                    type="text"
                    className="form-control-alternative text-right"
                    readOnly
                    name="total"
                    innerRef={register}
                  // value={formatCurrency({ amount: total })}
                  />
                </FormGroup>
              </Col>
            </Row>
          }
        </CardBody>
      </Card>
      {!get(form, 'id', false) && projectId && projectId !== 'Seleccione una opción' && supplierId && supplierId !== 'Seleccione una opción' &&
        <AddNoteLand register={register} projectId={projectId} supplierId={supplierId} error={error} />
      }
      {get(form, 'id') && <DependenciesList note={form} readOnly={readOnly} supplierId={supplier.id} />}
      <hr className="my-4" />
      {!readOnly && <Button disabled={loading || loadingNote} color="primary" onClick={handleSubmit(info => onSubmit(info, 'resume'))}>Guardar y Resumen</Button>}
      {!readOnly && <Button disabled={loading || loadingNote} color="primary" onClick={handleSubmit(info => onSubmit(info, 'new'))}>Guardar y Nueva nota</Button>}
    </Form>
  )
}

NoteForm.propTypes = {
  errors: PropTypes.shape({
    supplier: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  suppliers: PropTypes.arrayOf(PropTypes.shape({
    alias: PropTypes.string,
    id: PropTypes.number,
  })),
  supplier: PropTypes.shape({
    alias: PropTypes.string,
    id: PropTypes.number,
  }),
  loading: PropTypes.bool,
  loadingNote: PropTypes.bool,
  readOnly: PropTypes.bool,
  register: PropTypes.func,
  onSubmit: PropTypes.func,
  form: PropTypes.shape({}),
}

NoteForm.defaultProps = {
  loading: false,
  loadingNote: false,
  readOnly: false,
  suppliers: [],
  errors: {},
  form: {},
}

// export default NoteForm
const mapStateToProps = props => {
  const {
    landNotes: { landNotes }, projects: { projects, loading },
    notes: { error },
  } = props

  return {
    landNotes,
    projects,
    loadingProjects: loading,
    error,
  }
}

const mapDispatchToProps = dispatch => ({
  createNote: (values, history) => dispatch(createNote(values, history)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoteForm))
