import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faFileInvoice, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import ExpenseForm from 'components/Expenses/form'
import { loadSuppliers } from 'redux/actions/suppliers'
import { createExpense } from 'redux/actions/expenses'
import { loadWorks } from 'redux/actions/works'
import { loadTransactions } from 'redux/actions/transactions'


class NewExpense extends Component {

  state = {
    neodatas: []
  }

  componentDidMount() {
    this.props.loadSuppliers()
    this.props.loadWorks()
    this.props.loadTransactions()
  }

  handleOnChangeWork = id => {
    const { works: { works } } = this.props
    const work = works.find(work => work.id === parseInt(id))
    let neodatas = work && work.neodatas ? work.neodatas : []
    this.setState({ neodatas })
  }

  render() {
    const { createExpense, loading, suppliers, works, history, transactions } = this.props
    const { neodatas } = this.state

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">
                      <FontAwesomeIcon icon={faFileInvoice} className="mr-1" /> Nuevo Gasto<br />
                      <Button
                        tag={Link}
                        color="primary"
                        to="/admin/expenses"
                        size="sm"
                        className="mt-2"
                      >
                        <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                        Regresar a Lista
                      </Button>
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <ExpenseForm
              onSubmit={(info, action) => createExpense(info, action, history)}
              suppliers={suppliers}
              works={works}
              neodatas={neodatas}
              transactions={transactions}
              loading={loading}
              onChangeWork={this.handleOnChangeWork}
              supplierId={this.state.supplierId}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = props => {
  const { suppliers, works, transactions } = props
  return {
    suppliers,
    works,
    transactions
  }
}

const mapDispatchToProps = dispatch => ({
  loadSuppliers: () => dispatch(loadSuppliers({perPage: 1000})),
  loadWorks: () => dispatch(loadWorks()),
  loadTransactions: () => dispatch(loadTransactions()),
  createExpense: (values, action, history) => dispatch(createExpense(values, action, history)),
})

NewExpense.propTypes = {
  suppliers: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  works: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  loading: PropTypes.bool,
  loadSuppliers: PropTypes.func.isRequired,
  createExpense: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

NewExpense.defaultProps = {
  loading: false,
  suppliers: [],
  works: [],
  neodatas: [],
  transactions: []
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewExpense))
